* { box-sizing: border-box; }

html {
  height: 100%;
}

body {
  height: calc(100% - 50px);
}

body, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  text-decoration: none;
  color: inherit;
}

#root {
  height: 100%;
}
